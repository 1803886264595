<template>
  <div class="home">
    <page-title :title="'首页'">欢迎您</page-title>
    <div class="top-area" tips="头部统计区域">
      <div class="section-title">
        <span>数据概览</span>
      </div>
      <div class="top-area-content">
        <div class="count-block" style="background:#edfef9;">
          <div>全部案件数量</div>
          <div style="color:#00cb8d;">
            <span class="num-val">{{countObj.allcount}}</span>
            <span>件</span>
          </div>
        </div>
        <div class="count-block" style="background:#e8f5ff;">
          <div>已派单案件数量</div>
          <div style="color:#0090ff;">
            <span class="num-val">{{countObj.discount}}</span>
            <span>件</span>
          </div>
        </div>
        <div class="count-block" style="background:#ffebeb;">
          <div>未派单案件数量</div>
          <div style="color:#eb1616;">
            <span class="num-val">{{countObj.nodiscount}}</span>
            <span>件</span>
          </div>
        </div>
        <div class="count-block" style="background:#edfefe;">
          <div>已完成案件数量</div>
          <div style="color:#00b6d6;">
            <span class="num-val">{{countObj.finishcount}}</span>
            <span>件</span>
          </div>
        </div>
        <div class="count-block" style="background:#f2f6f5;">
          <div>客户数量</div>
          <div>
            <span class="num-val">{{countObj.customCount}}</span>
            <span>位</span>
          </div>
        </div>
      </div>

    </div>
    <div class="stat-area" tips="图表可视化区域">
      <div class="area-row">
        <div style="width:60%;">
          <div class="section-title">
            <span>案件数量增长趋势</span>
          </div>
          <div id="chartsOne" style="width:100%;height:400px;"></div>
        </div>
        <div style="width:36%;">
          <div class="section-title">
            <span>案件数量增长趋势</span>
          </div>
          <div id="chartsTwo" style="width:100%;height:400px;"></div>
        </div>
      </div>
    </div>
    <div class="btn-area" tips="快捷菜单">
      <div class="section-title">
        <span>快捷菜单</span>
      </div>
      <div class="btn-row">
        <div class="btn-block" style="background:#00cb8d;" @click="toPage('/case/index')">
          <span class="el-icon-s-finance" style="font-size:36px;"></span>
          <span>案件管理</span>
        </div>
        <div class="btn-block" style="background:#0090ff;" @click="toPage('/eleapp/index')">
          <span class="el-icon-s-marketing" style="font-size:36px;"></span>
          <span>电器管理</span>
        </div>
        <div class="btn-block" style="background:#33f3c7;" @click="toPage('/custom/indx')">
          <span class="el-icon-user-solid" style="font-size:36px;"></span>
          <span>客户管理</span>
        </div>
        <div class="btn-block" style="background:#00f3ff;">
          <span class="el-icon-s-data" style="font-size:36px;" @click="toPage('/setting/system/repairpeo')"></span>
          <span>员工管理</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'Home',
  data() {
    return {
      countObj:{},
      xData: ["2020-02", "2020-03", "2020-04", "2020-05"], //横坐标数据
      yData: [30, 132, 80, 134] //纵坐标数据，与横坐标对应
    }
  },
  mounted() {
    this.initEcharts();
    this.initEcharts2();
  },
  created(){
    this.initCount();
  },
  methods: {
    initCount(){
      const that = this;
      that.request.get("index/count").then(res=>{
        that.countObj = res.data;
      })
    },
    initEcharts() {
      const that = this;
      that.request.get("index/countByMon").then(res=>{
        if(res.code === 200){
          const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['案件总数', '已完成案件']
        },
        toolbox: {
          show: true,
          feature: {
            magicType: { show: true, type: ['line', 'bar'] },
          }
        },
        xAxis: [
          {
            type: 'category',
            data: res.data.month
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '案件总数',
            type: 'bar',
            data: res.data.total,
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            }
          },
          {
            name: '已完成案件',
            type: 'bar',
            data: res.data.end
          }
        ],
        grid: {
          top: 80,
          left: 50,
          right: 10,
        }
      };
      const myChart = echarts.init(document.getElementById("chartsOne"));// 图标初始化
      myChart.setOption(option);// 渲染页面
        }
      });
      
    },
    initEcharts2() {
      this.request.get("/index/initPieData").then(res=>{
        if(res.code ===200){
          const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          right: 'left'
        },
        series: [
          {
            name: '数量',
            type: 'pie',
            radius: '50%',
            data: res.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      const myChart1 = echarts.init(document.getElementById("chartsTwo"));// 图标初始化
      myChart1.setOption(option);// 渲染页面
        }
      });
    },
    toPage(e){
      this.$router.push(e);
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  .section-title {
    padding: 0px 0px 10px 0px;
  }
  .top-area {
    width: 100%;
    .top-area-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .count-block {
        padding: 20px 10px;
        width: 18%;
        min-width: 280px;
        color: #525c64;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;

        .num-val {
          font-size: 32px;
          margin-right: 5px;
        }
      }
    }
  }
  .stat-area {
    margin-top: 20px;
    width: 100%;
    .area-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  .btn-area{
    .btn-row{
      display: flex;
      flex-direction: row;
      justify-content:flex-start;
      align-items: center;
      flex-wrap: wrap;
      .btn-block{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 30px;
        margin-right: 20px;
        color:#ffffff;
        min-width: 90px;
        margin-bottom: 10px;
        &:hover{
          cursor: pointer;
        }
      }
    }

  }
}
</style>
